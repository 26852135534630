angular.module("KhSidebar").controller("SideNavbarController",
    function ($scope, $rootScope, SideNavFactory, SideNavBarService, PhoenixService, $location) {
        SideNavFactory.query(function (result) {
            $scope.apps = result;
            $rootScope.$watch("page_id", function (newValue, oldValue) {
                if (newValue) {
                    $scope.currentItem = $rootScope.page_id;
                    for (var i = 0; i < $scope.apps.length; i++) {
                        if ($scope.apps[i].page_id == $scope.currentItem) {
                            // FIXME remove this $rootScope dep...
                            $rootScope.current_page = $scope.apps[i].app_title;

                            // ... and start using this solution
                            SideNavBarService.setSelected($scope.apps[i]);
                        }
                    }
                }
            });
        });

        $scope.tenant = tenant_id;
        $scope.show_app_icons = show_app_icons == 'true';
        $scope.android_app_link = android_link;
        $scope.ios_app_link = ios_link;

        $scope.setCurrentItem = function (itemId) {
            if (itemId == $scope.currentItem) {
                $scope.currentItem = null;
            } else {
                $scope.currentItem = itemId;
            }
        };
        $scope.showChatIcon = function () {
            return show_chat_icon == 'true';
        };
        // $scope.freshdesk = freshdesk;
        $scope.freshdesk = "https://support.kisanhub.com/";

        if ($scope.tenant === 'niab') {
            $scope.privacy_policy_link = 'https://static.kisanhub.com/pages/niab/niab_digital_privacy_policy.pdf';
            $scope.terms_and_conditions_link = 'https://static.kisanhub.com/pages/niab/standard-service-terms-20200123.pdf';
        } else {
            $scope.privacy_policy_link = '/privacy-policy';
            $scope.terms_and_conditions_link = '/terms-and-conditions';
        }

        $scope.phoenixHome = () => {
            PhoenixService.getRefreshToken().then( (token)=> {
                window.location = `${PhoenixService.getHostUrl}?token=${token}`;
            });
        }

        $scope.appLinkClicked = (app) => {
            if (app.redirect_to_phoenix) {
                PhoenixService.getRefreshToken().then( (token)=> {
                    window.location = `${PhoenixService.getHostUrl}${app.app_link}/?token=${token}`;
                });
            } else {
                window.location = '/'+app.app_link;
            }
        }
    }
);
